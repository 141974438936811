// @flow
import React from 'react'
import isMobile from 'is-mobile'

import {createImageAltText} from '../../common/createImageAltText'
import type {GenericItemType} from '../../common/types'
import {genericItemTypes} from '../../common/constants'
import striptags from 'striptags'

import Link from '../Link'
import ItemInfo from '../ItemInfo'
import Background from '../Background'
import BroadcastGenres from '../BroadcastGenres'
import RecordingStatus from '../RecordingStatus'

import styles from './Tile.less'

export default ({item, className, uiLocation}: {item: GenericItemType, className?: string, uiLocation: string}) => {
  // $FlowFixMe
  const [isHovered, setIsHovered] = React.useState(false)
  // $FlowFixMe
  const [isMobileAndInView, setIsMobileAndInView] = React.useState(false)
  // $FlowFixMe
  const [divElement, setDivElement] = React.useState(null)

  if (!item) return null
  const image = item.show && item.background_image || item.thumbnail_image
  const imageAltText = createImageAltText(item)

  // $FlowFixMe
  React.useEffect(() => {
    if (!isMobile() || !divElement) return

    // Only create observer when element is available
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsMobileAndInView(true)
        } else {
          setIsMobileAndInView(false)
        }
      },
      {threshold: 0.5} // Adjust threshold as needed
    )

    observer.observe(divElement)
    return () => {
      observer.unobserve(divElement)
    }
  }, [divElement])
  const videoShouldPlay = isMobileAndInView || isHovered

  return (
    <div
      className={[styles.Wrapper, className].join(' ')}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      ref={setDivElement}
    >
      <Link item={item} uiLocation={uiLocation}>
        <Background
          className={styles.Image}
          image={image}
          altText={imageAltText}
          video={videoShouldPlay ? item.trailer_video : null}
          autoplay
          muted
        />
        <Info item={item} />
      </Link>
    </div>
  )
}

const Info = ({item, forceAudio}: {item: GenericItemType, forceAudio?: boolean}) =>
  <div className={styles.Info}>
    <RecordingStatus recording={item.recording} className={styles.RecordingStatus} />

    <h4 className={styles.Title}>{item.title}</h4>
    <ItemInfo item={item} />
    {item.type === genericItemTypes.article && <div>{item.summary}</div>}
    {item.recording && <BroadcastGenres recording={item.recording} />}
    {item.type === genericItemTypes.link && <p className={styles.LinkDescription} dangerouslySetInnerHTML={{__html: striptags(item.summary || item.description)}} />}
  </div>
